import { AxiosResponse } from "axios";

import {
  useIssue,
  useIssueRatingCategories,
  useIssueRatings,
  useMe,
  useUpdateAttachments,
} from "hooks";
import { useCallback, useMemo } from "react";
import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { IAISuggestions, IIssue, IIssueRating, IProblemArea } from "types";
import { IManagementProps, IValues } from "./IssueForm.types";
import {
  createIssue,
  updateIssue,
  bulkUpdateOrCreateIssueRatings,
} from "./IssueForm.services";
import {
  ISSUE_FIELD_NAMES,
  IssueInitial,
  IssuePayload,
} from "./IssueForm.constants";
import { FormikErrors, FormikHelpers } from "formik";
import { ENDPOINTS } from "const";
import { showErrorMessage, showSuccessMessage } from "utils";
import { createAISuggestionsIssues, IAIIssueProps } from "services";

export function useInitialState() {
  const { data: meData } = useMe();
  const { id } = useParams<{ id: string }>();
  const { data: categoriesData } = useIssueRatingCategories();
  const { data: issueRate } = useIssueRatings({
    userId: meData?.data.id,
    issueId: id,
    enabled: !!id,
  });
  const { data: issueData } = useIssue({ id });
  return useMemo(() => {
    return new IssueInitial(
      issueData?.data,
      categoriesData?.data,
      issueRate?.data?.items
    );
  }, [issueData?.data, categoriesData?.data, issueRate?.data?.items]);
}

interface IAIAnalyticsIssueProps
  extends Partial<UseMutationOptions<AxiosResponse<IAISuggestions>, any, any>> {
  id?: string | undefined;
}

export function useCreateAIAnalyticsIssue({ ...rest }: IAIAnalyticsIssueProps) {
  return useMutation<AxiosResponse<IAISuggestions>, any, any>(
    (issue: IAIIssueProps) => createAISuggestionsIssues({ ...issue }),
    {
      mutationKey: ["createAIAnalyticsIssue"],
      ...rest,
    }
  );
}

interface IIssueProps
  extends Partial<UseMutationOptions<AxiosResponse<IIssue>, any, any>> {
  id?: string | undefined;
}

export function useCreateIssue({ ...rest }: IIssueProps) {
  return useMutation<AxiosResponse<IIssue>, any, any>(
    (issue: IIssue) => createIssue({ issue }),
    {
      ...rest,
    }
  );
}

export function useUpdateIssue({
  id = "",
  ...rest
}: IIssueProps & {
  id: string | undefined;
}) {
  return useMutation<AxiosResponse<IIssue>, any, any>(
    (issue: IIssue) => updateIssue({ id, issue }),
    {
      ...rest,
    }
  );
}
interface IIssueRatingProps
  extends Partial<
    UseMutationOptions<AxiosResponse<Partial<IIssueRating>[]>, any, any>
  > {}
export function useUpdateOrCreateIssueRating({
  ...rest
}: IIssueRatingProps = {}) {
  return useMutation<AxiosResponse<Partial<IIssueRating>[]>, any, any>(
    async ({
      issueRating,
      issueId,
    }: {
      issueRating: ReturnType<IssuePayload["getIssueRatings"]>;
      issueId: string;
    }) => {
      return bulkUpdateOrCreateIssueRatings(issueRating, issueId);
    },
    {
      ...rest,
    }
  );
}

///
export function useSubmitHandler(
  success: (id?: string) => void,
  manage: IManagementProps
) {
  const params = useParams<{ id: string }>();

  const { mutateAsync: mutateCreateAISuggestionIssueAsync } =
    useCreateAIAnalyticsIssue({});
  const { mutateAsync: mutateCreateIssueAsync } = useCreateIssue({});
  const { mutateAsync: mutateUpdateIssueAsync } = useUpdateIssue({
    id: params.id,
  });
  const { mutateAsync: mutateUpdateOrCreateIssueRating } =
    useUpdateOrCreateIssueRating();
  const { data: categories } = useIssueRatingCategories();
  const { mutateAsync: updateAttachments } = useUpdateAttachments();

  const queryClient = useQueryClient();

  return useCallback(
    async (values: IValues, formikHelpers: FormikHelpers<IValues>) => {
      formikHelpers.setSubmitting(true);
      const invalidate = () => {
        queryClient.invalidateQueries([ENDPOINTS.ISSUES]);
        queryClient.resetQueries([ENDPOINTS.ISSUES]);
        queryClient.invalidateQueries([ENDPOINTS.ISSUE]);
        queryClient.invalidateQueries([ENDPOINTS.MAP_LOCATIONS]);
        queryClient.invalidateQueries([ENDPOINTS.ISSUE_RATING]);
      };
      const issue = new IssuePayload(
        { ...values, attachmentsType: "issues" },
        categories?.data
      );
      try {
        let issueId = params.id;

        const issueAnalyticsPayload = {
          issue_title: values.title,
          issue_location: values.location?.label,
          issue_details: values.details,
          issue_origin: values.origin,
          issue_importance: values.importance,
          ratings: values?.issueRating,
          docs: values?.attachments,
          links: undefined,
          labels: undefined,
        };
        const aiSuggestionsReviewResult =
          await mutateCreateAISuggestionIssueAsync(issueAnalyticsPayload);

        if (aiSuggestionsReviewResult.data.problem_areas.length > 0) {
          throw new Error("Error occurred! Check form!", {
            cause: aiSuggestionsReviewResult?.data?.problem_areas,
          });
        }
        if (issueId && manage.showIssueFields) {
          await mutateUpdateIssueAsync(issue.getIssue());
        }
        if (!issueId && manage.showIssueFields) {
          const created = await mutateCreateIssueAsync(issue.getIssue());
          issueId = created.data.id;
        }
        if (issueId && manage.showIssueFields) {
          await updateAttachments(issue.getAttachments(issueId));
        }
        if (issueId && manage.showRatings) {
          await mutateUpdateOrCreateIssueRating({
            issueRating: issue.getIssueRatings(issueId),
            issueId, //add issue dynamically here, because on creating issue we don't have issueId
          });
          showSuccessMessage("Success!");
          showSuccessMessage("Rating is updated!");
        }
        success(issueId);
        invalidate();
        formikHelpers.setSubmitting(false);
        queryClient.getMutationCache().clear();
      } catch (error) {
        formikHelpers.setSubmitting(false);

        if (error?.response?.data) {
          formikHelpers.setErrors(error.response.data);
        }
        if (error?.cause) {
          const errors = {};
          error.cause.forEach((problem: IProblemArea) => {
            errors[problem.input_name.toLocaleLowerCase()] =
              "Please review the highlighted sections for suggested improvements";
          });
          console.log("");

          setTimeout(() => {
            formikHelpers.setErrors(errors as FormikErrors<IValues>);
          }, 1000);
        }

        showErrorMessage("Error occurred! Check form!");
      }
    },
    [
      categories?.data,
      manage.showIssueFields,
      manage.showRatings,
      mutateCreateAISuggestionIssueAsync,
      mutateCreateIssueAsync,
      mutateUpdateIssueAsync,
      mutateUpdateOrCreateIssueRating,
      params.id,
      queryClient,
      success,
      updateAttachments,
    ]
  );
}
