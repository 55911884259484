import { ACTIVITY_TYPES, CONTENT_QUALITY_LEVELS, ENTITY_TYPES } from "const";
import { Step } from "react-joyride";

interface IBase {
  id?: string;
  createdAt: string;
  updatedAt: string;
}

export type Range = 0 | 1 | 2 | 3 | 4 | 5;
export interface ILocation extends IBase, IPoint {
  location: { type: "Point"; coordinates: string[] } | null;
  placeId: string | null;
  formattedAddress: string | null;
  country: string | null;
  administrativeArea: string | null;
  city: string | null;
  route: string | null;
  streetNumber: string | null;
  postalCode: string | null;
  compoundCode: string | null;
  globalCode: string | null;
  issue: string;
}

export interface ITag extends IBase {
  name: string;
  description: string;
}
export interface IMotivationalTag<TMotivational = string, TTag = string>
  extends IBase {
  tag: TTag;
  motivational?: TMotivational;
}

export interface IMotivational<TUser = string, TMotivationalTags = string>
  extends IBase {
  user: TUser;
  title: string;
  description: string;
  videoLink: string;
  image: string;
  thumbnailLink: string;
  tags: TMotivationalTags[];
  likes: number;
  dislikes: number;
  views: number;
  rates?: IRatings[];
}
export enum USER_ROLES {
  ADMIN = "admin",
  USER = "user",
}

export interface IUser<TAsset = string> extends IBase {
  email: string;
  firstName?: string;
  lastName?: string;
  avatar?: TAsset;
  primaryLocation?: ILocation;
  lat?: string;
  lng?: string;
  placeId?: string;
  formattedAddress?: string;
  about?: string;
  isPrivate?: boolean;
  mediaLinks?: string[];
  reputationScore?: number;
  issueRatingCategories?: IIssueRatingCategory[];
  roles?: USER_ROLES[];
}

export interface ISkill extends IBase {
  name: string;
  description: string;
}

export interface IActionGroupSkill<TSkill = string, TActionGroup = string>
  extends IBase {
  skill: TSkill;
  timeRequired: number;
  actionGroup?: TActionGroup;
  label?: string;
}

export interface IUserSkill {
  id?: string;
  skill: ISkill;
  user: IUser;
}

export interface ISolution<
  TUser = string,
  TIssue = string,
  TPrimaryActionGroup = string,
  TAttachments = string
> extends IBase {
  user: TUser;
  issue: TIssue;
  title: string;
  description: string;
  effectiveness: string;
  impactRanking: number;
  primaryActionGroup: TPrimaryActionGroup;
  numberOfRatings: number;
  analyticsData?: string;
  attachments?: TAttachments[];
  tags?: string[];
  deletedAt?: string;
}
export type FullSolutionType = ISolution<
  IUser,
  FullIssueType,
  FullActionGroupType,
  IAttachment<IAsset>
>;
//
export interface IIssueRating<TIssueRatingCategory = string> extends IBase {
  rate: Range;
  issueRatingCategory: TIssueRatingCategory;
}
export interface IIssue<
  TLocation = string,
  TUser = string,
  TSolution = string,
  TIssueRating = string,
  TIssueRatingCategory = string,
  TAttachment = string
> extends IBase {
  analyticsData: string;
  user: TUser;
  title: string;
  details: string;
  origin: string;
  importance: string;
  location: TLocation;
  impactRanking: number;
  rating?: number;
  numberOfRatings: number;
  issueRating?: TIssueRating;
  primaryClassification?: TIssueRatingCategory;
  secondaryClassification?: TIssueRatingCategory;
  solutions?: TSolution[];
  attachments?: TAttachment[];
  tags?: string[];
  deletedAt?: string;
}

export type FullIssueType = IIssue<
  Partial<ILocation>,
  Partial<IUser>,
  Partial<ISolution>,
  Partial<IIssueRating>,
  Partial<IIssueRatingCategory>,
  Partial<IAttachment<IAsset>>
>;

export enum ISSUE_RATING_CATEGORIES {
  EDUCATION = "education",
  TECHNOLOGY = "technology",
  INFRASTRUCTURE = "infrastructure",
  CLIMATE = "climate",
  HEALTH = "health",
  POVERTY_HUNGER = "povertyAndHunger",
  SECURITY_AND_CONFLICT = "securityAndConflict",
  JUSTICE_AND_EQUITY = "justiceAndEquity",
  POLITICS_AND_GOVERNANCE = "politicsAndGovernance",
  EMPLOYMENT_AND_ECONOMY = "employmentAndEconomy",
  UNITY_AND_DIVISION = "unityAndDivision",
}
export interface IIssueRatingCategory extends IBase {
  category: ISSUE_RATING_CATEGORIES;
  title: string;
  description: string;
}

export interface IActionGroup<
  TSkills = string,
  TUser = IUser,
  TSolution = string,
  TActionUsers = string,
  TAttachment = string
> extends IBase {
  user?: TUser;
  title: string;
  description: string;
  stage: string;
  contact: string;
  contactUrl: string;
  address: string;
  logo: string;
  schedule: string;
  likes: number;
  dislikes: number;
  membersCount?: number;
  solutions?: (TSolution | undefined)[] | undefined;
  rates?: IRatings[];
  skills?: TSkills[];
  actionGroupUsers?: TActionUsers[];
  isPrivate?: boolean;
  analyticsData?: string;
  attachments?: TAttachment[];
  tags?: string[];
  deletedAt?: string;
}
export type FullActionGroupType = Partial<
  IActionGroup<
    Partial<IActionGroupSkill<Partial<ISkill>>>,
    Partial<IUser>,
    Partial<FullSolutionType>,
    Partial<
      IActionGroupUsers<
        Partial<IUser>,
        Partial<IActionGroup<any, any, any, any, any>>
      >
    >,
    Partial<IAttachment<IAsset>>
  >
>;

export interface ICategories<T> {
  climate: T;
  employment: T;
  education: T;
  addiction: T;
  povertyAndHunger: T;
  insecurityAndInstability: T;
  health: T;
  injusticeAndDiscrimination: T;
  corruption: T;
  disunityAndDivision: T;
}

export interface IAverageIssueRating {
  [key: string]: number;
  count: number;
}

export interface ISolutionRating extends IBase {
  solution: string;
  rate: number;
}

export interface IActionGroupRatings extends IBase {
  rate: number;
}

export interface IMotivationalRatings extends IBase {
  rate: number | null;
}

export interface IRatings extends IBase {
  rate: number;
}

export type ActionGroupUserStatus = "approved" | "pending" | "declined";
export type ActionGroupUserMembership = "creator" | "admin" | "member";
export interface IActionGroupUsers<TUser = string, TActionGroup = string>
  extends IBase {
  actionGroup: TActionGroup;
  status: ActionGroupUserStatus;
  role: ActionGroupUserMembership;
  user: TUser;
}

export interface IActivity<TUser = string> extends IBase {
  activityUser: TUser;
  entityUser: TUser;
  activityType: ACTIVITY_TYPES;
  entityType: ENTITY_TYPES;
  representEntityType: ENTITY_TYPES;
  entity: string;
  representEntityId: string;
  content: string;
  seen: boolean;
  representEntityUser: TUser;
  title: string;
}

export type AddressType = "regular" | "city" | "country";

export interface IMapLocation {
  count: number;
  entity: string | string[];
  location: number[]; //lng is first location item, lat is second location item
  type?: AddressType;
}

export interface IPoint {
  lat: number;
  lng: number;
}

export interface IBackgroundImage {
  url: string;
  color: string;
}

export interface IHandoutActionGroupInfo {
  title: string[];
  telegram_url: string[];
  description: string[];
  schedule: string[];
  contact: string[];
  stage: string[];
  logo: string[];
  issue_title: string[];
  issue_location: string[];
  issue_rating: string[];
}

export interface IHandout {
  action_group_info: IHandoutActionGroupInfo[];
}

export interface IProductTour {
  title: string;
  orderNumber: number;
  scenarioStep: Step[];
  startLocationPathname: string;
}

export interface IUserDeviceTokens<TUser = string> extends IBase {
  token: string;
  user: TUser;
}

export interface IActionGroupHangout {
  id: string;
  title: string | null;
  address: string | null;
  description: string | null;
  schedule: string | null;
  contactUrl: string | null;
  logo: string | null;
  stage: string | null;
  significantIssueId: string;
  significantIssueTitle: string | null;
  significantIssueRating: number | null;
  significantAddress: string | null;
  issueRatings: {
    [key: string]: string;
  };
}

export interface IAttachment<TAsset = string, TUser = string> extends IBase {
  type: "file" | "link" | "text";
  asset: TAsset;
  data: any;
  user: TUser;
  title?: string;
  commonFor?: "issues" | "solutions" | "actionGroups";
}
export interface IAsset extends IBase {
  type: "image" | "document";
  path: string;
  eTag: string;
  mimetype: string;
  url?: string;
  originalName?: string;
}

export interface IMandatoryFile {
  url: string;
  mimeType: string;
}

export interface IMandatoryAttachments {
  items: IAttachment[];
  meta: {
    totalItems: number;
    itemCount: number;
    itemsPerPage: number;
    totalPages: number;
    currentPage: number;
  };
}

//

export interface IAISuggestions {
  entity: string;
  problem_areas: IProblemArea[];
  more_feedback: IMoreFeedback;
}

export interface IProblemArea {
  input_name: string;
  quality: CONTENT_QUALITY_LEVELS;
  user_input: string;
  feedback: string;
  suggested_texts: ISuggestedText[];
}

export interface ISuggestedText {
  suggestion1: string;
  suggestion2: string;
  suggestion3: string;
}

export interface IMoreFeedback {
  quality: CONTENT_QUALITY_LEVELS;
  feedback: string;
}
