import { useField, useFormikContext } from "formik";
import classnames from "classnames";

import InputElement, { IInputProps } from "../InputElement";
import { Label } from "components";
import MotivationalInputElement from "components/MotivationalInputElement";
import { FormikInputContentQuality } from "components/FormikInputContentQuality";
import { IAISuggestions, IProblemArea } from "types";

interface IProps extends IInputProps {
  classNames?: {
    inputFieldContainer?: string;
    mainContainer?: string;
    inputContainer?: string;
    input?: string;
    error?: string;
    icon?: string;
    label?: string;
    message?: string;
  };
  checkAIContentQuality?: boolean;
  AISuggestionProblemArea?: IProblemArea;
  AISuggestionFieldName?: string;
  className?: string;
  showOnlyCustomMessage?: boolean;
  name: string;
  label?: string;
  icon?: string;
  message?: React.ReactNode | string;
  forUrlInput?: boolean;
  isMotivationalStyle?: boolean;
}

function getTouched(touched: { [key: string]: any }, input: string) {
  const itemNames = input.match(/\w+/gi);
  //return touched[itemName][0];
  if (itemNames?.length) {
    return !!itemNames.reduce((acc, curr) => {
      if (acc) {
        acc = acc[curr];
      }
      return acc;
    }, touched);
  }
}

function InputField({
  classNames,
  name,
  className,
  label,
  checkAIContentQuality,
  AISuggestionProblemArea,
  AISuggestionFieldName,
  icon,
  showOnlyCustomMessage,
  message,
  isMotivationalStyle,
  ...rest
}: IProps) {
  const [field, form] = useField(name);
  const { touched } = useFormikContext(); //leave this line
  const isInsideFieldArray = !!field?.name?.match(/\w+\./gi);
  const fieldIsTouched = isInsideFieldArray
    ? getTouched(touched, field.name)
    : touched[field.name];

  const error = fieldIsTouched && form.error;
  const showError = !!error;
  return (
    <div
      className={classnames(
        classNames?.inputFieldContainer,
        "flex flex-col relative gap-1"
      )}
    >
      <div className="flex flex-row justify-between ">
        {label && (
          <Label
            className={rest.readOnly ? "font-bold" : classNames?.label}
            name={label}
          />
        )}
        {checkAIContentQuality && AISuggestionFieldName && (
          <FormikInputContentQuality
            name={name}
            label={label}
            suggestionFormikFieldName={AISuggestionFieldName}
            AISuggestionProblemArea={AISuggestionProblemArea}
          />
        )}
      </div>

      <>
        {isMotivationalStyle ? (
          <MotivationalInputElement
            isError={showError}
            className={classnames(!isMotivationalStyle && "mt-1", className)}
            {...field}
            {...rest}
          />
        ) : (
          <InputElement
            isError={showError}
            className={className}
            classNames={classNames}
            {...field}
            {...rest}
          />
        )}
      </>
      {!showOnlyCustomMessage && (showError || message) && (
        <div
          className={classnames(
            classNames?.error,
            " text-gray-400  font-light text-sm line-clamp-1",
            {
              "text-red-400": showError,
            }
          )}
        >
          {error || message}
        </div>
      )}
      {showOnlyCustomMessage && message && (
        <div
          className={classnames(
            classNames?.error,
            classNames?.message,
            "  text-gray-400  font-light text-sm "
          )}
        >
          {message}
        </div>
      )}
    </div>
  );
}

export default InputField;
