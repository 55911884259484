import React, { memo } from "react";
import classnames from "classnames";
import "./Button.css";
import { twMerge } from "tailwind-merge";

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  className?: string;
  onClick?: (params: any) => void;
  customPaddings?: boolean;
  deepCustomize?: boolean;
  isDefault?: boolean;
  isStyleless?: boolean;
  isReverse?: boolean;
  inGroupStyle?: boolean;
  innerRef?: (node?: Element | null | undefined) => void;
  classNames?: {
    content?: string;
  };
}

function Button({
  className,
  children,
  customPaddings,
  isDefault = true,
  isStyleless = false,
  classNames,
  isReverse,
  inGroupStyle = true,
  ...rest
}: IProps) {
  const classes = twMerge(
    classnames(
      className,
      "relative flex outline-none focus:outline-none items-center text-sm rounded-lg group",
      { "px-4 py-3": !customPaddings },
      { "relative default-button": isDefault && !isStyleless },
      { "bg-white": isReverse },
      { "bg-gray-100": isReverse && rest.disabled },
      { "cursor-not-allowed ": rest.disabled }
    )
  );

  return (
    <button className={classes} {...rest} ref={rest.innerRef}>
      <span
        className={twMerge(
          classnames({
            "default-button-bg": !rest.disabled && !isStyleless,
            "!bg-zambezi h-full w-full absolute left-0 top-0":
              rest.disabled && !isStyleless,
            "white-button-bg": isReverse && !isStyleless,
          })
        )}
      />
      <p
        className={twMerge(
          classnames(
            classNames?.content,
            "pointer-events-none font-semibold",

            { "button-text z-10": !isStyleless },
            {
              "!text-black group-hover:!text-white":
                (isStyleless || isReverse) && inGroupStyle,
            },
            { "text-black": (isStyleless || isReverse) && !inGroupStyle }
          )
        )}
      >
        {children}
      </p>
    </button>
  );
}

export default memo(Button);
