/* eslint-disable no-useless-escape */

import * as yup from "yup";

import { FIELD_NAMES } from "./MotivationalForm.constants";
import { getYouTubeVideoId, matchYoutubeUrl } from "utils";

const MAX_NAME_LENGTH = 70;
const MIN_NAME_LENGTH = 3;
const MIN_DESC_LENGTH = 12;
export const MAX_TEXT_LENGTH = 120;
export const MAX_DESC_LENGTH = 980;

export const ALLOWED_IMAGE_FILE_EXTENSIONS_AND_TYPES = [
  ".jpg",
  ".jpeg",
  ".png",
  ".PNG",
  ".JPG",
  ".gif",
  "image/png",
];

const a = FIELD_NAMES.VIDEO_LINK;
const b = FIELD_NAMES.IMAGE;

const motivationalSchema = {
  [FIELD_NAMES.IMAGE]: yup.string().nullable(),
  [FIELD_NAMES.TITLE]: yup
    .string()
    .trim()
    .max(MAX_NAME_LENGTH, `max title length is ${MAX_NAME_LENGTH}`)
    .min(MIN_NAME_LENGTH, `min title length is ${MIN_NAME_LENGTH}`)
    .required("Title is required"),
  [FIELD_NAMES.DESCRIPTION]: yup
    .string()
    .min(MIN_DESC_LENGTH, `Description should be longer`)
    .required("Description is required"),

  [FIELD_NAMES.VIDEO_LINK]: yup
    .string()
    .trim()
    .test("youtubeFormat", "Link should be of youtube format", (value) => {
      if (typeof value === "string" && value?.trim()) {
        const res = matchYoutubeUrl(value);

        if (res) {
          return !!getYouTubeVideoId(value);
        } else {
          return false;
        }
      }
      return true;
    })
    .nullable(),
  [FIELD_NAMES.THUMBNAIL_LINK]: yup.string().trim().nullable(),

  validator: yup.bool().when([a, b], {
    is: (a: any, b: any): any => !!a || !!b,
    then: yup.bool(),
    otherwise: yup.bool().required("Please choose a video link or an image"),
  }),
};

const tagsSchema = {
  [FIELD_NAMES.TAGS]: yup
    .array(yup.mixed().required("Category is required"))
    .min(1, "Please select at least one category")
    .nullable(),
};

export default function formValidationSchema() {
  let schema = { ...motivationalSchema, ...tagsSchema };

  return yup.object().shape(schema);
}
