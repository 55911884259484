import { memo, useEffect } from "react";
import ReactDOM from "react-dom";

import { Card } from "components";

import classnames from "classnames";

import { XIcon } from "@heroicons/react/outline";
import React from "react";
import { twMerge } from "tailwind-merge";
import { Popover } from "@headlessui/react";

interface IAISuggestionModal {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  className?: string | undefined;
  showFooterDivider?: boolean;
  disableScrollOnOpen?: boolean;
  actionCallback?: any;
  header: React.ReactNode | string;
  content: React.ReactNode | string;
  footer?: React.ReactNode | string;
  classNames?: {
    footer?: string;
    content?: string;
    header?: string;
  };
}

function AISuggestionPopover({
  className,
  setShow,
  header,
  content,
  footer,
  classNames,
  showFooterDivider,
  disableScrollOnOpen,
}: IAISuggestionModal) {
  useEffect(() => {
    if (disableScrollOnOpen) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [disableScrollOnOpen]);

  return (
    <Popover className="relative">
      <div
        className={classnames(
          className,
          "z-40 top-0 fixed  left-0 w-full bg-gray-500 bg-opacity-30 h-full outline-none flex justify-center  items-center overflow-y-hidden p-4"
        )}
      >
        <Card
          showFooterDivider={false}
          showHeaderDivider={false}
          shouldHover={false}
          className="flex-1 max-w-[900px] py-0 z-40"
          header={
            <div className="flex justify-between items-center pb-1">
              <div className="grid grid-flow-row gap-x-2 items-center max-h-[70px] mr-2 ">
                <span className="font-bold leading-6 text-lg line-clamp-2">
                  {header}
                </span>
              </div>
              <XIcon
                onClick={() => {
                  setShow(false);
                }}
                className="h-6 w-6 shrink-0 cursor-pointer"
                aria-hidden="true"
              />
            </div>
          }
          classNames={{
            header: twMerge(
              classnames(
                "px-4 sm:px-9 py-2 justify-center sm:max-w-[900px] bg-[#F4F4F4] h-[116px]",
                classNames?.header
              )
            ),
            content: twMerge(
              classnames("px-4 sm:px-9  flex-1 sm:max-w-[900px]")
            ),
            footer: twMerge(
              classnames(
                "px-3 sm:px-9 h-[60px] py-2 flex-1 !justify-center sm:max-w-[900px]",
                classNames?.footer
              )
            ),
          }}
          content={
            <div
              className={twMerge(
                classnames(
                  "flex flex-col items-center py-4 text-lg",
                  classNames?.content
                )
              )}
            >
              {content}
            </div>
          }
          footer={<>{footer}</>}
        />
      </div>
    </Popover>
  );
}

export default memo(AISuggestionPopover);
