export enum ENDPOINTS {
  USER_DEVICE_TOKENS = "/user-device-tokens",
  ATTACHMENTS = "/attachments",
  ACTIVITIES = "/activities",
  ACTIVITIES_EVENTS = "/activities/events",
  ACTION_GROUPS = "/action-groups",
  ACTION_GROUPS_ASSIGN_ACTION_GROUPS = "/action-groups/assign-action-groups",
  ACTION_GROUPS_LOCATIONS = "/action-groups/locations",
  USER_SKILLS = "/user-skills",
  SKILLS = "/skills",
  USERS = "/users",
  SIGN_UP = "/sign-up",
  SIGN_IN_VERIFY = "/sign-in-verify",
  ME = "/me",
  RESET_PASSWORD = "/reset-password",
  REQUEST_RESET_PASSWORD = "/request-reset-password",
  RESET_VERIFY = "/",
  SOLUTIONS = "/solutions",
  SOLUTION_RATINGS = "/solution-ratings",
  ISSUES = "/issues",
  ISSUE_RATINGS = "/issue-ratings",
  ISSUES_LOCATIONS = "/issues/locations",
  MOTIVATIONAL = "/motivational",
  ATTACHMENTS_DOWNLOAD = "/attachments/download",
  MOTIVATIONAL_TAGS = "/motivational-tags",
  MOTIVATIONAL_TAGS_BULK = "/motivational-tags/bulk",
  MOTIVATIONAL_RATINGS = "/motivational-ratings",
  MOTIVATIONAL_VIEWS = "/motivational/views",
  TAGS = "/tags",
  SOLUTIONS_LOCATIONS = "/solutions/locations",
  ACTION_GROUP_RATINGS = "/action-group-ratings",
  ACTION_GROUP_SKILLS = "/action-group-skills",
  ACTION_GROUP_USERS = "/action-group-users",
  ACTION_GROUP_SKILLS_BULK = "/action-group-skills/bulk",
  COMMENTS_CONFIG = "/comments-config",
  BACKGROUNDS = "/backgrounds",
  ISSUE_RATING_CATEGORIES = "/issue-rating-categories",
  MANDATORY_FILES = "/assets/files",
  ///
  USER_TOKEN_DEVICE = "user-token-device", // just key
  ACTION_GROUPS_RATINGS = "action-groups-ratings", // just key
  ACTION_GROUPS_SKILLS = "action-groups-skills", // just key
  ISSUE = "issue", // just key
  ISSUE_RATING = "issue-rating", // just key
  MAP_LOCATIONS = "map-locations", // just key
  SOLUTION_RATING = "solution-rating", // just key
  /// AI
  ANALYTICS_ISSUES = "analytics/perform_issue_qa",
  ANALYTICS_SOLUTIONS = "analytics/perform_solution_qa",
  ANALYTICS_ACTION_GROUPS = "analytics/perform_action_group_qa",
  //
}
