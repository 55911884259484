import classnames from "classnames";
import { CONTENT_QUALITY_LEVELS } from "const";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { twMerge } from "tailwind-merge";
import lightBulb from "assets/images/lightbulb.png";
import { ReactComponent as LightBulbBig } from "assets/images/lighbulb_big.svg";
import { ReactComponent as LightBulbSmall } from "assets/images/lighbulb_small.svg";
import { ReactComponent as CarouselArrowLeft } from "assets/images/carousel_arrow_left.svg";
import { ReactComponent as CarouselArrowRight } from "assets/images/carousel_arrow_right.svg";
import { IProblemArea } from "types";
import { AISuggestionModal } from "components/AISuggestionModal";
import { Textarea } from "components/Textarea";
import FormikTextarea from "components/FormikTextarea";
import { useField } from "formik";
import { Button } from "components/Button";
import { MAX_TEXT_LENGTH } from "components/IssueForm/IssueForm.schema";

interface IQualityBarProps {
  AISuggestionProblemArea?: IProblemArea;
}

function QualityBar({ AISuggestionProblemArea }: IQualityBarProps) {
  return (
    <div
      className={twMerge(
        classnames(
          " flex justify-start rounded-[10px] h-[6px]  w-[63px] bg-gray-100 overflow-hidden"
        )
      )}
    >
      <span
        className={twMerge(
          classnames("", {
            " bg-quality-content-red w-[10px] rounded-[10px]":
              AISuggestionProblemArea?.quality ===
                CONTENT_QUALITY_LEVELS.POOR ||
              !AISuggestionProblemArea?.quality ||
              !Object.values(CONTENT_QUALITY_LEVELS).includes(
                AISuggestionProblemArea.quality as CONTENT_QUALITY_LEVELS
              ),
            "bg-quality-content-yellow w-[26px] rounded-[10px]":
              AISuggestionProblemArea?.quality === CONTENT_QUALITY_LEVELS.LOW,
            "bg-quality-content-green w-full rounded-[10px]":
              AISuggestionProblemArea?.quality ===
                CONTENT_QUALITY_LEVELS.HIGH || !AISuggestionProblemArea,
          })
        )}
      />
    </div>
  );
}

interface IProps {
  AISuggestionProblemArea?: IProblemArea;
  name: string;
  label?: string;
  suggestionFormikFieldName: string;
  className?: string;
  classNames?: {
    icon?: string;
  };
}

function FormikInputContentQuality({
  AISuggestionProblemArea,
  name,
  label,
  suggestionFormikFieldName,
  className,
  classNames,
}: IProps) {
  const [field, form, { setValue, setTouched }] = useField(name);
  const [suggestionField, helper, { setValue: setSuggestionValue }] = useField(
    suggestionFormikFieldName
  );
  const [showSuggestionModal, setShowSuggestionModal] = useState(false);
  const [
    indexOfCurrentSelectedSuggestion,
    setIndexOfCurrentSelectedSuggestion,
  ] = useState(0);

  const availableSuggestionsArray = useMemo(
    () =>
      AISuggestionProblemArea &&
      Object.values(AISuggestionProblemArea.suggested_texts[0]),
    [AISuggestionProblemArea]
  );

  const nextSuggestion = useCallback(() => {
    const nextIndex = indexOfCurrentSelectedSuggestion + 1;
    if (
      availableSuggestionsArray &&
      nextIndex < availableSuggestionsArray.length
    ) {
      setIndexOfCurrentSelectedSuggestion(nextIndex);
      setSuggestionValue(availableSuggestionsArray[nextIndex]);
    }
  }, [
    availableSuggestionsArray,
    indexOfCurrentSelectedSuggestion,
    setSuggestionValue,
  ]);

  const prevSuggestion = useCallback(() => {
    const prevIndex = indexOfCurrentSelectedSuggestion - 1;
    if (availableSuggestionsArray && prevIndex >= 0) {
      setIndexOfCurrentSelectedSuggestion(prevIndex);
      setSuggestionValue(availableSuggestionsArray[prevIndex]);
    }
  }, [
    availableSuggestionsArray,
    indexOfCurrentSelectedSuggestion,
    setSuggestionValue,
  ]);

  useEffect(() => {
    if (
      !suggestionField.value &&
      availableSuggestionsArray &&
      availableSuggestionsArray[0] !== "" &&
      availableSuggestionsArray[0] !== undefined &&
      !helper.touched
    ) {
      setSuggestionValue(availableSuggestionsArray[0]);
    }
  }, [
    AISuggestionProblemArea,
    availableSuggestionsArray,
    helper.touched,
    setSuggestionValue,
    showSuggestionModal,
    suggestionField.value,
  ]);

  return (
    <div className="flex flex-row items-center text-sm font-light relative">
      <div className="flex flex-row gap-2 items-center text-sm font-light relative">
        <QualityBar AISuggestionProblemArea={AISuggestionProblemArea} />
        <p>
          Content quality:{" "}
          {AISuggestionProblemArea?.quality || CONTENT_QUALITY_LEVELS.HIGH}
        </p>

        {AISuggestionProblemArea &&
          AISuggestionProblemArea?.quality !== CONTENT_QUALITY_LEVELS.HIGH && (
            <LightBulbSmall
              className="h-[36px] w-[36px] cursor-pointer "
              viewBox="8 8 28 28"
              onClick={() => {
                setShowSuggestionModal(true);
              }}
            />
            // <div
            //   onClick={() => {
            //     setShowSuggestionModal(true);
            //   }}
            //   className="bg-[#027B6B] rounded-tl-[15px] rounded-br-[15px] h-[34px] w-[34px] flex items-center justify-center cursor-pointer hover:bg-[rgb(42,116,106)] "
            // >
            //   <img
            //     src={lightBulb}
            //     alt="level 1"
            //     className={twMerge(
            //       classnames("w-[22px] h-[22px] invert", classNames?.icon)
            //     )}
            //   />
            // </div>
          )}
      </div>
      {showSuggestionModal && (
        <AISuggestionModal
          setShow={setShowSuggestionModal}
          header={
            <div className="flex flex-row items-center gap-4 ">
              <LightBulbBig
                className="h-[55px] w-[55px] "
                viewBox="13 13 44 44"
              />
              {/* <div className="bg-[#027B6B] rounded-tl-[15px] rounded-br-[15px] h-[55px] w-[55px] flex items-center justify-center ">
                <img
                  src={lightBulb}
                  alt="level 1"
                  className={twMerge(
                    classnames("w-[36px] h-[36px] invert", classNames?.icon)
                  )}
                />
              </div> */}
              <div className="flex flex-col">
                <p className="font-light text-sm">
                  It's too little information. Add more text to reveal the topic
                  better
                </p>
                <p>
                  Tip for you: Here A.I. could write an improvement for this
                  text.
                </p>
              </div>
            </div>
          }
          content={
            <div className="flex flex-col w-full">
              <FormikTextarea
                label={label}
                name={name}
                disabled
                className=" px-10"
                classNames={{
                  input:
                    "disabled:bg-white outline outline-quality-content-yellow",
                }}
                message={
                  <div className="flex justify-between text-gray-400  font-light text-sm">
                    <span>Please add some more details</span>{" "}
                    <span
                      className={classnames({
                        "text-red-400": form?.error === "max",
                      })}
                    >{`${field?.value?.length}/${MAX_TEXT_LENGTH}`}</span>
                  </div>
                }
              />

              <div className="flex flex-row gap-4 items-center w-full">
                <CarouselArrowLeft
                  onClick={prevSuggestion}
                  className={classnames(
                    "h-[24px] w-[24px] mt-[12px] cursor-pointer opacity-50 hover:opacity-100",
                    {
                      hidden: indexOfCurrentSelectedSuggestion === 0,
                    }
                  )}
                />
                <FormikTextarea
                  name={suggestionFormikFieldName}
                  className={classnames("flex-1", {
                    "pl-[42px]": indexOfCurrentSelectedSuggestion === 0,
                    "pr-[42px]":
                      indexOfCurrentSelectedSuggestion ===
                      (availableSuggestionsArray &&
                        availableSuggestionsArray?.length - 1),
                  })}
                  classNames={{
                    input:
                      "disabled:bg-white outline outline-quality-content-green !select-none",
                  }}
                  label="Suggested text:"
                  message={
                    <div className="flex justify-between text-gray-400  font-light text-sm">
                      <span>How did this issue start?</span>{" "}
                      <span
                        className={classnames({
                          "text-red-400": form?.error === "max",
                        })}
                      >{`${suggestionField?.value?.length}/${MAX_TEXT_LENGTH}`}</span>
                    </div>
                  }
                />
                <CarouselArrowRight
                  onClick={nextSuggestion}
                  className={classnames(
                    "h-[24px] w-[24px] mt-[12px] cursor-pointer opacity-50 hover:opacity-100",
                    {
                      hidden:
                        indexOfCurrentSelectedSuggestion ===
                        (availableSuggestionsArray &&
                          availableSuggestionsArray?.length - 1),
                    }
                  )}
                />
              </div>
              <div className="flex flex-row gap-1 w-full items-center justify-center">
                {availableSuggestionsArray?.map((suggestion, index) => (
                  <div
                    key={index}
                    className={twMerge(
                      classnames("h-[10px] w-[10px] rounded-full bg-gray-200", {
                        "bg-[#666666]":
                          index === indexOfCurrentSelectedSuggestion,
                      })
                    )}
                  />
                ))}
              </div>
            </div>
          }
          classNames={{ footer: "!justify-end" }}
          footer={
            <div className=" flex gap-4 items-center px-4  ">
              <Button
                isReverse
                type="button"
                className=" justify-center border"
                onClick={() => {
                  setShowSuggestionModal(false);
                }}
              >
                Cancel
              </Button>
              <button
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-3 bg-quality-content-green text-base font-medium text-white hover:bg-quality-content-green focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={() => {
                  setValue(suggestionField.value);
                  setShowSuggestionModal(false);
                }}
              >
                Accept
              </button>
            </div>
          }
        />
      )}
    </div>
  );
}

export default memo(FormikInputContentQuality);
